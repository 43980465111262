/** @jsx jsx */
import React, { useState, useEffect, useContext } from "react";
import { LogoImage, NavLinks } from "@/data";
import { Col, Container, Row } from "react-bootstrap";
import { SearchContext } from "@/context/search-context";
import { MenuContext } from "@/context/menu-context";
import { Link } from "gatsby";
import { jsx } from '@emotion/react'
import { 
  header,
  logo,
  mainMenu,
  submenu,
  navigator,
  btnAgendeSuaVisita, 
  socialIcon,
}  from '../assets/styles/Header.styles'

import Instagram from '../assets/images/lp/icons/instagram-header.svg'
import Facebook from '../assets/images/lp/icons/facebook-header.svg'
import Youtube from '../assets/images/lp/icons/youtube-header.svg'

const HeaderTwo = () => {
  const [sticky, setSticky] = useState(false);
  const { searchStatus, updateSearchStatus } = useContext(SearchContext);
  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const handleSearchClick = e => {
    e.preventDefault();
    updateSearchStatus(!searchStatus);
  };
  const handleMenuClick = e => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };

  const handleScroll = () => {
    if (window.scrollY > 70) {
      setSticky(true);
    } else if (window.scrollY < 70) {
      setSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sticky]);

  return (
    <header css={header} dark
      className={`header_01 black_color ${
        true === sticky ? "fixedHeader animated flipInX" : null
      }`}
      id="header"
    >
      <Container>
        <Row className="justify-content-between">
          <Col className="col-6" lg={3} md={3} sm={3}>
            <div css={logo}>
              <Link to="/">
                <img src={LogoImage.light} alt="" />
              </Link>
            </div>
          </Col>
          <Col lg={6} sm={8} md={7} className="d-none d-lg-block">
            <nav css={mainMenu} dark className="text-center">
              <ul>
                {NavLinks.map((links, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        undefined !== links.subItems
                          ? "menu-item-has-children"
                          : ""
                      }`}
                    >
                      <Link to={links.url}>{links.name}</Link>
                      {undefined !== links.subItems ? (
                        <ul css={submenu} dark className="sub-menu">
                          {links.subItems.map((subLinks, index) => (
                            <li key={index}>
                              <Link to={subLinks.url}>{subLinks.name}</Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </nav>
          </Col>
          <Col lg={3} md={4} sm={4} className="d-none d-lg-block">
            <div className="text-right" style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{display: 'flex'}}>
                <a href="https://www.instagram.com/bertoni.elos/" target="_blank"><img src={Instagram} /></a>
                <a css={socialIcon} href="https://www.facebook.com/bertonielos" target="_blank"><img src={Facebook} /></a>
              </div>
              <Link css={btnAgendeSuaVisita} to='#formulario' style={{ marginLeft: 34 }}>
                Visita
              </Link>
            </div>
          </Col>
          {/* MOBILE */}
          <Col lg={2} md={2} sm={4} className="col-6">
            <div css={navigator} className="navigator text-right d-block d-sm-none">
              <a
                id="open-overlay-nav"
                className="menu hamburger"
                onClick={handleMenuClick}
                href="#"
              >
                <i className="mei-menu"></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderTwo;
